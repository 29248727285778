import * as React from "react";
import styled from "styled-components";
import { InstantSearch, connectHits } from "react-instantsearch-dom";

import { useRecipeLandingQuery } from "@query";
import CategoryMenu from "@shared/categoryMenu";
import PreviewAll from "@shared/previewAll";
import { Container, FlexGrid } from "@util/standard";
import Hero from "@shared/hero";
import RecipePreviewCarousel from "@components/recipes/recipePreviewCarousel";
import AllergensFilter from "@shared/allergensFilter";
import {  pageWidthMargin, isBrowser } from "@util/helper";
import { searchClient } from "@util/searchClient";
import { HeroCarousel, SearchBar, Breadcrumb } from "@global";
import SEO from "@shared/seo";
import Layout from "@shared/layout";
import { BreadcrumbPageContext } from "@util/types";
import { useCheckScreenWidth } from "@util/hooks";
import { useStore } from "@state/store";
import {
  Maybe,
  SanityRecipeConnection,
  SanityRecipeLandingPage,
  SanityRecipeTagConnection,
} from "@graphql-types";

interface Props {
  allRecipes?: boolean;
  pageContext: BreadcrumbPageContext;
  allSanityRecipe?: SanityRecipeConnection;
  allSanityRecipeTag?: SanityRecipeTagConnection;
  sanityRecipeLandingPage?: Maybe<SanityRecipeLandingPage> | undefined;
  iban?: string;
}

const sortRecipes = (
  allSanityRecipeTag: SanityRecipeTagConnection,
  iban?: string
) => {
  const { allergenFilter } = useStore();

  //Create recipe tags
  const sortedRecipeCategories: any = [];

  //Add all tags to array
  allSanityRecipeTag.nodes.forEach(tag => {
    let recipesArray = tag.recipes;

    //Filter recipes by iban
    if (iban && recipesArray && recipesArray.length > 0) {
      recipesArray = recipesArray.filter(recipe => {
        if (recipe == null || recipe.regions == null) return null;
        return recipe.regions.some(region => {
          if (region == null) return null;
          return region.iban === iban;
        });
      });
    }

    //Filter recipes by allergen
    if (allergenFilter && recipesArray && allergenFilter.name != "None") {
      recipesArray = recipesArray.filter(recipe => {
        if (
          recipe == null ||
          recipe.info == null ||
          recipe.info.allergens == null
        )
          return null;

        return recipe.info.allergens.some(allergen => {
          if (allergen == null) return null;
          return allergen.title === allergenFilter.name;
        });
      });
    }

    let tagGroup = {
      id: tag._id,
      name: tag.main?.title,
      slug: tag.main?.slug?.current,
      recipes: recipesArray,
    };
    sortedRecipeCategories.push(tagGroup);
  });

  return sortedRecipeCategories;
};

const StyledContainer = styled(Container)`
  div[class^="collectionAccordian__AccordionsContainer"] {
    width: 100%;
  }
`;

const Hits = ({ hits }: any) => {
  return <PreviewAll algoliaRecipes={hits} iban={process.env.GATSBY_IBAN} />;
};

const CustomHits = connectHits(Hits);

const RecipesTemplate = (props: Props) => {
  if (!isBrowser()) return null;

  const {
    allRecipes,
    pageContext,
    allSanityRecipe,
    allSanityRecipeTag,
    iban,
    sanityRecipeLandingPage,
  } = props;
  const { searchParams } = useStore();
  const sortedRecipeCategories =
    allSanityRecipeTag && sortRecipes(allSanityRecipeTag, iban);
  const { isMobileWidth } = useCheckScreenWidth();
  const margin = pageWidthMargin();

  React.useEffect(() => {
    useStore.setState({
      searchParams: {
        searchString: "",
        hasSearched: false,
        globalSearch: false,
      },
    });
  }, []);

  if (!isBrowser()) return null;

  return (
    <>
      <SEO seoData={sanityRecipeLandingPage?.seo} />
      <Layout>
        {sanityRecipeLandingPage &&
        sanityRecipeLandingPage.additionalHeros &&
        sanityRecipeLandingPage.additionalHeros.length > 0 ? (
          <HeroCarousel
            hero={sanityRecipeLandingPage?.hero}
            additionalHeros={sanityRecipeLandingPage.additionalHeros}
          />
        ) : (
          <Hero sanityHero={sanityRecipeLandingPage?.hero} />
        )}
        <Breadcrumb pageContext={pageContext} />

        <InstantSearch searchClient={searchClient} indexName="everyday_recipes">
          <Container backgroundColor="white">
            <StyledContainer
              height="auto"
              width="100%"
              padding={isMobileWidth === true ? `55px 0` : `55px ${margin} 0`}
              display="flex"
              flexDirection="row"
            >
              <FlexGrid
                gridMargin="120px"
                laptopGridMargin="75px"
                tabletGridMargin="55px"
              >
                {isMobileWidth !== true && (
                  <Container
                    width="calc(230px + 120px)"
                    laptopWidth="300px"
                    tabletWidth="200px"
                    margin="0 0 55px"
                  >
                    <Container flexDirection="column">
                      <SearchBar />
                      <AllergensFilter />
                      <CategoryMenu
                        isRecipes
                        isAll={allRecipes}
                        allSanityRecipeTag={allSanityRecipeTag}
                      />
                    </Container>
                  </Container>
                )}

                <Container
                  width="calc(100% - 230px - 120px)"
                  laptopWidth="calc(100% - 300px)"
                  tabletWidth="calc(100% - 200px)"
                  mobileWidth="100%"
                  flexDirection="column"
                >
                  {searchParams.hasSearched ? (
                    <CustomHits />
                  ) : (
                    <>
                      {!allRecipes &&
                        sortedRecipeCategories &&
                        sortedRecipeCategories.map((category, i) => {
                          return (
                            <Container key={i} position="relative">
                              <RecipePreviewCarousel
                                recipes={category.recipes.slice(0, 10)}
                                category={category}
                                showTabletNav
                                width={
                                  isMobileWidth === true
                                    ? `calc(100% - 30px)`
                                    : `100%`
                                }
                              />
                            </Container>
                          );
                        })}
                      {allRecipes && (
                        <PreviewAll
                          allRecipes
                          allSanityRecipe={allSanityRecipe}
                          iban={process.env.GATSBY_IBAN}
                        />
                      )}
                    </>
                  )}
                </Container>
              </FlexGrid>
            </StyledContainer>
          </Container>
        </InstantSearch>
      </Layout>
    </>
  );
};

export default RecipesTemplate;
