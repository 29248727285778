import React from "react";
import styled from "styled-components";

import { Link } from "gatsby";
import { assets, colors } from "@util/constants";

interface Props {
  linkTo?: string;
  linkText?: string;
  stretch?: number;
  isWhite?: boolean;
  onClick?: () => void;
}

const StyledLink = styled(Link)<{
  stretch?: number;
}>`
  display: flex;
  align-items: center;
  margin: auto 0;
  ${({ stretch }) =>
    stretch &&
    `
    width: 100%;
    justify-content: space-between;
  `}
  img {
    margin-left: 5px;
    transform: translateZ(0);
    transition-duration: 0.1s;
    transition-property: transform;
    transition-timing-function: ease-out;
  }
  &:hover {
    img {
      transform: translateX(4px);
    }
  }
`;
const StyledA = styled.a<{
  stretch?: number;
}>`
  display: flex;
  align-items: center;
  margin: auto 0;

  ${({ stretch }) =>
    stretch &&
    `
    width: 100%;
    justify-content: space-between;
  `}
  img {
    margin-left: 5px;
    transform: translateZ(0);
    transition-duration: 0.1s;
    transition-property: transform;
    transition-timing-function: ease-out;
  }
  &:hover {
    img {
      transform: translateX(4px);
    }
  }
`;

const TextButton = ({ linkTo, linkText, stretch, isWhite, onClick }: Props) => {
  if (onClick) {
    return (
      <StyledA
        onClick={onClick}
        stretch={stretch as number}
        style={{ color: isWhite ? colors.white : colors.green }}
      >
        {linkText ?? ""}
        <img
          src={isWhite ? assets.arrowRightWhite : assets.arrowRight}
          width="10px"
        />
      </StyledA>
    );
  }

  if (!linkTo) return null;

  return (
    <StyledLink
      to={linkTo}
      stretch={stretch as number}
      style={{ color: isWhite ? colors.white : colors.green }}
    >
      {linkText ?? "View All"}
      <img
        src={isWhite ? assets.arrowRightWhite : assets.arrowRight}
        width="10px"
      />
    </StyledLink>
  );
};

export default TextButton;
